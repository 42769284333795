import api from '@whatsnxt/core-util/src/ApiClient/xiorInstance';
import {
  GET_USER_QUERY,
  GOOGLE_LOGIN_MUTATION,
  LOGIN_MUTATION,
  LOGOUT_MUTATION,
  OTP_MUTATION,
  SIGNUP_MUTATION,
} from './gqlQueries/authQuery';
import { checkGraphqlResponse } from '../utils/commonHelper';

export const AuthAPI = {
  otp: async function (payload: any) {
    const { data } = await api.request({
      data: {
        query: OTP_MUTATION,
        variables: payload,
      },
    });

    return checkGraphqlResponse(data).sendOtp;
  },

  signup: async function (payload: any) {
    const { data } = await api.request({
      data: {
        query: SIGNUP_MUTATION,
        variables: payload,
      },
    });

    return checkGraphqlResponse(data).createUser;
  },

  verifyGoogleCode: async function (access_token: string) {
    try {
      const response = await api.post('', {
        query: GOOGLE_LOGIN_MUTATION,
        variables: { access_token }
      });
      console.log('AuthAPI:: verifyGoogleCode:: response: ', response.data);
      return response.data.data.verifyGoogleCode;
    } catch (error) {
      console.error('AuthAPI:: verifyGoogleCode:: error: ', error);
      throw error;
    }
  },

  googleLogin: async function (access_token: string) {
    try {
      const response = await api.post('', {
        query: GOOGLE_LOGIN_MUTATION,
        variables: { access_token }
      });
      return response.data.data.googleLogin;
    } catch (error) {
      console.error('AuthAPI:: googleLogin:: error: ', error);
      throw error;
    }
  },

  login: async function (payload: any) {
    const requestData: any = {
      data: {
        query: LOGIN_MUTATION,
        variables: { email: payload.email, password: payload.password },
      },
    };

    if (process.env.NODE_ENV === 'production') {
      requestData.headers = { origin: process.env.NEXT_PUBLIC_APP_URL };
      requestData.adapter = 'http';
    }

    const { data } = await api.request(requestData);
    return checkGraphqlResponse(data).login;
  },

  logout: async function () {
    const requestData: any = {
      data: {
        query: LOGOUT_MUTATION,
      },
    };

    if (process.env.NODE_ENV === 'production') {
      requestData.headers = { origin: process.env.NEXT_PUBLIC_APP_URL };
      requestData.adapter = 'http';
    }

    const { data } = await api.request(requestData);
    return checkGraphqlResponse(data).logout;
  },
  getUser: async function () {
    const requestData: any = {
      data: {
        query: GET_USER_QUERY,
        variables: {},
      },
    };

    if (process.env.NODE_ENV === 'production') {
      requestData.headers = {};
      requestData.headers.origin = process.env.NEXT_PUBLIC_APP_URL;
      requestData.adapter = 'http';
    }

    const { data } = await api.request(requestData);

    return checkGraphqlResponse(data).getUserProfile;
  },
};
