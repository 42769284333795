export const RESET_PASS_MUTATION = () => `
  mutation ResetPasswordReq($email: String!) {
    resetPasswordRequest(email: $email)
  }
`;

export const SET_PASS_MUTATION = () => `
  mutation SetPassword(
    $userId: String!,
    $token: String!,
    $email: String!,
    $password: String!
  ) {
    resetPassword(
      userId: $userId,
      token: $token,
      email: $email,
      password: $password
    )
  }
`;

export const UPDATE_USER_TYPE_MUTATION = () => `
  mutation SetUserType(
    $creator: Boolean!,
    $email: String!
  ) {
    updateUserType(
      creator: $creator,
      email: $email
    ) {
      id
      name
      creator
      email
    }
  }
`;
