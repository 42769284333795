import React from 'react';
import { useState } from 'react';
import { HiOutlineClipboardDocument, HiOutlineClipboardDocumentCheck } from 'react-icons/hi2';
const ClipboardCopy = ({
  url
}: any) => {
  const style = {
    color: 'green',
    cursor: 'pointer'
  };
  const [copied, setCopied] = useState<boolean>(false);
  const copyToClipboard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    navigator.clipboard.writeText(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return <div onClick={copyToClipboard} data-sentry-component="ClipboardCopy" data-sentry-source-file="ClipBoardCopy.tsx">
      {copied ? <HiOutlineClipboardDocumentCheck size={20} style={style} /> : <HiOutlineClipboardDocument size={20} style={style} />}
    </div>;
};
export default ClipboardCopy;