import React from 'react';
import { Box, Grid, Skeleton, Stack } from '@mantine/core';
interface SkeletonProps {
  color?: string;
  height?: number | string;
  width?: number | string;
  visible?: boolean;
  circle?: boolean;
  animate?: boolean;
  mb?: string;
  radius?: string;
}
const SkeletonRectangle: React.FC<SkeletonProps> = ({
  color = 'red',
  height = 30,
  width = 200,
  visible = true,
  circle = false,
  animate = true
}) => {
  return <Skeleton height={height} width={width} visible={visible} circle={circle} animate={animate} style={{
    backgroundColor: color
  }} data-sentry-element="Skeleton" data-sentry-component="SkeletonRectangle" data-sentry-source-file="index.tsx" />;
};
const SkeletonText: React.FC<SkeletonProps> = ({
  color = undefined,
  height = 25,
  width = '100%',
  visible = true,
  circle = false,
  animate = true,
  mb = 'xl',
  radius = 'sm'
}) => {
  return <Skeleton height={height} width={width} visible={visible} circle={circle} animate={animate} style={{
    backgroundColor: color
  }} mb={mb} radius={radius} data-sentry-element="Skeleton" data-sentry-component="SkeletonText" data-sentry-source-file="index.tsx" />;
};
const SkeletonCard: React.FC = () => {
  return <Stack align="center" data-sentry-element="Stack" data-sentry-component="SkeletonCard" data-sentry-source-file="index.tsx">
      <SkeletonRectangle height={150} data-sentry-element="SkeletonRectangle" data-sentry-source-file="index.tsx" />
      <SkeletonText width={'72%'} data-sentry-element="SkeletonText" data-sentry-source-file="index.tsx" />
    </Stack>;
};
const SkeletonCardContent: React.FC = () => {
  return <Box data-sentry-element="Box" data-sentry-component="SkeletonCardContent" data-sentry-source-file="index.tsx">
      <Grid grow gutter="lg" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Grid.Col span={4} data-sentry-element="unknown" data-sentry-source-file="index.tsx"><SkeletonCard data-sentry-element="SkeletonCard" data-sentry-source-file="index.tsx" /></Grid.Col>
        <Grid.Col span={4} data-sentry-element="unknown" data-sentry-source-file="index.tsx"><SkeletonCard data-sentry-element="SkeletonCard" data-sentry-source-file="index.tsx" /></Grid.Col>
        <Grid.Col span={4} data-sentry-element="unknown" data-sentry-source-file="index.tsx"><SkeletonCard data-sentry-element="SkeletonCard" data-sentry-source-file="index.tsx" /></Grid.Col>
      </Grid>
      <Grid grow gutter="lg" data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Grid.Col span={4} data-sentry-element="unknown" data-sentry-source-file="index.tsx"><SkeletonCard data-sentry-element="SkeletonCard" data-sentry-source-file="index.tsx" /></Grid.Col>
        <Grid.Col span={4} data-sentry-element="unknown" data-sentry-source-file="index.tsx"><SkeletonCard data-sentry-element="SkeletonCard" data-sentry-source-file="index.tsx" /></Grid.Col>
        <Grid.Col span={4} data-sentry-element="unknown" data-sentry-source-file="index.tsx"><SkeletonCard data-sentry-element="SkeletonCard" data-sentry-source-file="index.tsx" /></Grid.Col>
      </Grid>
    </Box>;
};
const SkeletonBlogContent: React.FC = () => {
  return <div style={{
    marginTop: '0.5rem'
  }} data-sentry-component="SkeletonBlogContent" data-sentry-source-file="index.tsx">
      <SkeletonText width="90%" mb="lg" radius='xl' data-sentry-element="SkeletonText" data-sentry-source-file="index.tsx" />
      <SkeletonText width="85%" mb="lg" radius='xl' data-sentry-element="SkeletonText" data-sentry-source-file="index.tsx" />
      <SkeletonText width="75%" mb="lg" radius='xl' data-sentry-element="SkeletonText" data-sentry-source-file="index.tsx" />
      <SkeletonText width="95%" mb="lg" radius='xl' data-sentry-element="SkeletonText" data-sentry-source-file="index.tsx" />
      <SkeletonText width="90%" mb="lg" radius='xl' data-sentry-element="SkeletonText" data-sentry-source-file="index.tsx" />
      <SkeletonText width="85%" mb="lg" radius='xl' data-sentry-element="SkeletonText" data-sentry-source-file="index.tsx" />
      <SkeletonText width="75%" mb="lg" radius='xl' data-sentry-element="SkeletonText" data-sentry-source-file="index.tsx" />
      <SkeletonText width="95%" mb="lg" radius='xl' data-sentry-element="SkeletonText" data-sentry-source-file="index.tsx" />
    </div>;
};
export { SkeletonRectangle, SkeletonText, SkeletonCard, SkeletonCardContent, SkeletonBlogContent };