import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store/store';
import { getPosts } from '../../store/slices/contentSlice';
import Pagination from '../Pagination/pagination';
import 'react-loading-skeleton/dist/skeleton.css';
import ErrorTemplate from './ErrorTemplate';
import TutorialCard from '../Cards/Tutorial';
import BlogCard from '../Cards/Blog';
import { SkeletonCardContent } from '@whatsnxt/core-ui';
import { Box, Container, Group } from '@mantine/core';

/* eslint-disable-next-line */
export type ContentType = "blog" | "tutorial" | "both";
/* eslint-disable-next-line */
export interface ContentProps {
  type: ContentType;
}
function ContentComponent(props: ContentProps) {
  const {
    type
  } = props;
  const content = useSelector((store: RootState) => store.content);
  const totalCount = useSelector((store: RootState) => store.content.totalCount);
  const dispatch = useDispatch<AppDispatch>();
  const [recordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const nPages = Math.ceil(totalCount / recordsPerPage);
  useEffect(() => {
    dispatch(getPosts({
      start: 1,
      limit: recordsPerPage,
      type
    }));
  }, []);
  const pageChangeCallback = (page: number) => {
    dispatch(getPosts({
      start: page,
      limit: recordsPerPage,
      type
    }));
  };
  return <Group data-sentry-element="Group" data-sentry-component="ContentComponent" data-sentry-source-file="ContentComponent.tsx">
      {content.error !== '' && <ErrorTemplate />}
      {content.loading ? <SkeletonCardContent /> : content.articles && content.articles.length > 0 && content.articles.map((item: any, i) => <Box key={i}>
            {item?.tutorial ? <TutorialCard tutorial={item} /> : <BlogCard blog={item} />}
          </Box>)}

      {!content.loading && content.error === '' && content.articles.length > 0 ? <Container fluid>
          <Pagination pageChangeCallback={pageChangeCallback} nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </Container> : null}
    </Group>;
}
export default ContentComponent;