'use client';

import { NavigationProgress, nprogress } from '@mantine/nprogress';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
export default function CustomProgressBar() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    // Start the progress bar when pathname or search params change
    nprogress.start();

    // Simulate a delay before marking progress as complete
    const timer = setTimeout(() => {
      nprogress.complete();
    }, 500);

    // Clean up the timer on component unmount or when dependencies change
    return () => {
      clearTimeout(timer);
      nprogress.complete(); // Ensure progress is completed on cleanup
    };
  }, [pathname, searchParams]);
  return <NavigationProgress color="#fe4a55" data-sentry-element="NavigationProgress" data-sentry-component="CustomProgressBar" data-sentry-source-file="CustomProgressBar.tsx" />;
}