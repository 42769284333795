import React from 'react';
import { FaFacebook } from 'react-icons/fa';
const FacebookShare = ({
  url
}: any) => {
  const style = {
    cursor: 'pointer'
  };
  const handleShare = (e: any) => {
    e.preventDefault();
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`, '_blank');
  };
  return <div onClick={handleShare} data-sentry-component="FacebookShare" data-sentry-source-file="FacebookShare.tsx"><FaFacebook size={20} style={style} data-sentry-element="FaFacebook" data-sentry-source-file="FacebookShare.tsx" /></div>;
};
export default FacebookShare;