import React from 'react';
import styles from './Logo.module.css';
export const Logo = ({
  color = 'black',
  className = ''
}: {
  color?: 'white' | 'black';
  className?: string;
}) => {
  return <svg width="150.000000pt" height="20.000000pt" viewBox="0 0 702.000000 80.000000" className={`${className} ${styles['navbar-brand']}`} data-sentry-element="svg" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
      <g transform="translate(0.000000,80.000000) scale(0.100000,-0.100000)" data-sentry-element="g" data-sentry-source-file="Logo.tsx">
        <path fill={color} d="M3702 774 c-72 -19 -104 -37 -140 -79 -38 -43 -46 -70 -40 -136 9 -95 46 -128 273 -255 62 -35 70 -43 70 -69 0 -28 -3 -30 -43 -33 -25 -2 -85 9 -153 28 -102 29 -112 30 -134 16 -53 -35 -36 -93 43 -151 171 -125 500 -107 570 32 43 86 35 168 -23 232 -19 21 -87 68 -152 104 -137 78 -146 84 -162 115 -10 19 -10 28 4 48 23 36 81 32 180 -12 104 -47 126 -51 149 -25 52 58 -62 164 -206 191 -71 14 -173 11 -236 -6z" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
        <path fill={color} d="M89 765 c-40 -22 -60 -85 -69 -216 -15 -209 14 -337 96 -428 65 -73 139 -106 234 -106 64 0 83 4 127 29 l51 28 58 -28 c150 -73 328 -4 404 158 41 85 54 180 46 323 -7 144 -19 189 -56 227 -24 23 -37 28 -79 28 -60 0 -95 -14 -109 -45 -6 -13 -14 -119 -17 -237 -4 -117 -11 -228 -17 -246 -12 -38 -49 -66 -77 -57 -33 10 -40 51 -28 187 14 169 0 309 -34 344 -52 51 -175 37 -202 -23 -8 -20 -12 -96 -12 -254 0 -245 -4 -263 -53 -257 -14 2 -34 16 -46 31 -20 26 -21 45 -26 260 -4 154 -9 239 -17 253 -24 41 -122 57 -174 29z" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
        <path fill={color} d="M1223 770 c-13 -5 -32 -24 -43 -42 -19 -31 -20 -51 -20 -306 0 -362 8 -390 110 -400 107 -10 141 30 148 181 l5 97 112 0 113 0 4 -111 3 -111 33 -29 c59 -53 169 -33 198 36 11 27 14 98 14 334 l0 300 -24 27 c-20 24 -32 28 -88 32 -61 4 -68 2 -98 -25 -38 -34 -49 -79 -50 -200 l0 -83 -110 0 -110 0 0 109 c0 125 -11 166 -51 186 -31 16 -111 19 -146 5z" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
        <path fill={color} d="M2251 761 c-67 -25 -113 -60 -156 -119 -68 -92 -88 -207 -75 -413 13 -186 33 -215 143 -207 80 6 107 34 107 114 l0 54 95 0 95 0 0 -54 c0 -80 27 -108 107 -114 73 -5 99 7 120 56 21 50 34 253 23 350 -17 152 -80 256 -191 317 -72 39 -187 45 -268 16z m161 -172 c32 -17 48 -71 48 -164 l0 -85 -95 0 -95 0 0 78 c0 94 14 146 43 167 25 18 70 20 99 4z" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
        <path fill={color} d="M2783 770 c-27 -11 -43 -40 -43 -80 0 -70 47 -100 158 -100 l69 0 7 -240 c4 -132 12 -254 18 -270 5 -17 23 -37 39 -45 38 -20 119 -19 148 1 46 32 51 66 51 319 l0 235 74 0 c83 0 132 14 146 40 6 10 10 39 10 64 0 36 -5 48 -26 65 -26 20 -38 21 -328 20 -165 0 -311 -4 -323 -9z" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
        <path fill={color} d="M4661 766 c-49 -27 -50 -39 -51 -382 l0 -321 23 -21 c32 -30 106 -31 133 -1 16 18 19 40 22 165 l3 145 130 -123 c175 -167 231 -208 279 -208 56 0 87 15 105 50 14 26 15 74 13 351 l-3 321 -24 19 c-30 25 -92 25 -122 0 -23 -18 -24 -26 -27 -166 l-4 -147 -151 149 c-84 83 -166 158 -184 167 -38 19 -109 20 -142 2z" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
        <path fill={color} d="M5529 756 c-47 -32 -69 -67 -69 -112 0 -50 28 -92 118 -174 l77 -70 -76 -77 c-98 -100 -119 -132 -119 -178 0 -31 8 -45 44 -81 36 -37 50 -44 82 -44 58 0 97 30 171 130 36 50 70 89 74 88 4 -2 39 -44 78 -95 79 -103 103 -123 144 -123 58 0 129 59 142 119 9 43 -15 83 -112 182 l-83 86 88 89 c107 107 124 144 94 202 -38 75 -122 103 -188 63 -18 -11 -60 -57 -94 -103 -35 -46 -66 -87 -71 -91 -4 -5 -39 32 -79 82 -39 50 -83 100 -97 111 -34 27 -81 25 -124 -4z" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
        <path fill={color} d="M6323 770 c-27 -11 -43 -40 -43 -80 0 -71 47 -100 160 -100 l70 0 1 -47 c1 -27 4 -142 8 -256 l6 -209 33 -29 c27 -24 41 -29 85 -29 32 0 61 6 76 16 46 32 51 66 51 319 l0 235 80 0 c93 0 133 16 148 57 14 42 3 91 -26 113 -23 19 -40 20 -326 19 -165 0 -311 -4 -323 -9z" data-sentry-element="path" data-sentry-source-file="Logo.tsx" />
      </g>
    </svg>;
};