import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
const WhatsappShare = ({
  url
}: any) => {
  const style = {
    cursor: 'pointer'
  };
  const handleShare = (e: any) => {
    e.preventDefault();
    window.open(`https://wa.me/?text=${encodeURIComponent(url)}`, '_blank');
  };
  return <div onClick={handleShare} data-sentry-component="WhatsappShare" data-sentry-source-file="WhatsappShare.tsx"><FaWhatsapp size={20} style={style} data-sentry-element="FaWhatsapp" data-sentry-source-file="WhatsappShare.tsx" /></div>;
};
export default WhatsappShare;