import type { ContentType } from '../components/Content/ContentComponent';
import api from '@whatsnxt/core-util/src/ApiClient/xiorInstance';
import {
  GET_POSTS_QUERY,
  GET_POST_QUERY,
  GET_POST_SLUG_QUERY,
  GET_POSTS_CATEGORY_QUERY,
  GET_COMMENTS_QUERY,
  POST_COMMENT_MUTATION,
  EDIT_COMMENT_MUTATION,
  DELETE_COMMENT_MUTATION,
  TOGGLE_LIKE_MUTATION,
  TOGGLE_DISLIKE_MUTATION,
  FLAG_MUTATION,
} from './gqlQueries/contentQuery';

export const ContentAPI = {
  getPosts: async function (start: number, limit: number, type: ContentType) {
    const { data } = await api.request({
      data: {
        query: GET_POSTS_QUERY,
        variables: { start, limit, type },
      },
    });

    return data.data ? data.data.privPosts || data.data.pubPosts : [];
  },

  getPostsById: async function (id: string) {
    const { data } = await api.request({
      data: {
        query: GET_POST_QUERY,
        variables: { id },
      },
    });
    console.log("🚀 ~ data:", data)

    return data.data ? data.data.privPost || data.data.pubPost : {};
  },
  getPostsBySlug: async function (slug: string) {
    const startTime = performance.now(); // Record start time
    const { data } = await api.request({
      data: {
        query: GET_POST_SLUG_QUERY,
        variables: { slug },
      },
    });
    const endTime = performance.now(); // Record end time

    return data.data ? data.data.privPostSlug || data.data.pubPostSlug : {};
  },

  getPostsByCategory: async function (categoryName: string) {
    const { data } = await api.request({
      data: {
        query: GET_POSTS_CATEGORY_QUERY,
        variables: { categoryName },
      },
    });
    return data.data
      ? data.data.privPostsCategory || data.data.pubPostsCategory
      : [];
  },
  getComments: async function (payload: any) {
    const { data } = await api.request({
      data: {
        query: GET_COMMENTS_QUERY,
        variables: {
          id: payload.blogId,
          contentId: payload.contentId,
          offset: payload.offset,
          limit: payload.limit,
          parentId: payload.parentId || null,
        },
      },
    });
    return data.data ? data.data.getComments || data.data.pubComments : [];
  },
  postComment: async function (payload: any) {
    const { data } = await api.request({
      data: {
        query: POST_COMMENT_MUTATION,
        variables: {
          contentId: payload.contentId,
          content: payload.content,
          email: payload.email,
          parentId: payload.parentId,
        },
      },
    });
    return data.data ? data.data.createComment : {};
  },
  editComment: async function (payload: any) {
    const { data } = await api.request({
      data: {
        query: EDIT_COMMENT_MUTATION,
        variables: {
          contentId: payload.contentId,
          content: payload.comment,
          id: payload.commentId,
          email: payload.email
        },
      },
    });
    return data.data ? data.data.editComment : {};
  },
  deleteComment: async function (payload: any) {
    const { data } = await api.request({
      data: {
        query: DELETE_COMMENT_MUTATION,
        variables: {
          id: payload.id,
          contentId: payload.contentId,
          email: payload.email
        },
      },
    });
    return data.data ? data.data.deleteComment : {};
  },
  likeComment: async function (payload: {
    commentId: string,
    email: string
  }) {
    const { data } = await api.request({
      data: {
        query: TOGGLE_LIKE_MUTATION,
        variables: {
          id: payload.commentId,
          email: payload.email
        },
      },
    });
    return data.data ? data.data.toggleLike : {};
  },

  dislikeComment: async function (payload: {
    commentId: string,
    email: string
  }) {
    const { data } = await api.request({
      data: {
        query: TOGGLE_DISLIKE_MUTATION,
        variables: {
          id: payload.commentId,
          email: payload.email
        },
      },
    });
    return data.data ? data.data.toggleDislike : {};
  },

  flagComment: async function (payload: any) {
    const { data } = await api.request({
      data: {
        query: FLAG_MUTATION,
        variables: {
          id: payload.id,
          email: payload.email
        },
      },
    });
    return data;
  }
};
