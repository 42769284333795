import winstonLogger from '../winstonLogger';
type telemetryType = {
  Timestamp: Date;
  ObservedTimestamp: Date;
  TraceId: string;
  SpanId: string;
  TraceFlags: string;
  SeverityText: string;
  SeverityNumber: number;
  Body: unknown;
};
const uniqueId = (): string => {
  const dateString = Date.now().toString(36);
  const randomness = Math.random().toString(36).substr(2);
  return dateString + randomness;
};
const generateTelemetryData = (
  SeverityText: string,
  SeverityNumber: number,
  Body: unknown
): telemetryType => {
  const telemetryData: telemetryType = {
    Timestamp: new Date(),
    ObservedTimestamp: new Date(),
    TraceId: uniqueId(),
    SpanId: uniqueId(),
    TraceFlags: uniqueId(),
    SeverityText,
    SeverityNumber,
    Body
  };
  return telemetryData;
};
export class AppLogger {
  constructor() {}
  debug(body: unknown): void {
    winstonLogger.debug(
      JSON.stringify(generateTelemetryData('Debug', 1, body))
    );
  }
  info(body: unknown): void {
    winstonLogger.info(JSON.stringify(generateTelemetryData('Info', 1, body)));
  }
  warn(body: unknown): void {
    winstonLogger.warn(JSON.stringify(generateTelemetryData('Warn', 1, body)));
  }
}