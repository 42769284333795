'use client';

import { createContext, useEffect, useState, ReactNode } from 'react';
import { useRouter } from 'next/navigation';
import { initAuthFromCookies, isAuthenticated } from './authUtils';
import { handleLogin, handleLogout } from './authActions';
import { AuthContextType, AuthenticatedUserType, LoginParamsType } from './authTypes';

// ** Defaults
const defaultProvider: AuthContextType = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => null,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  profileApi: () => Promise.resolve(),
  isAuthenticated: false
};

// Create Auth Context
export const AuthContext = createContext<AuthContextType>(defaultProvider);
interface AuthProviderProps {
  children: ReactNode;
}

// AuthProvider Component
export const AuthProvider = ({
  children
}: AuthProviderProps) => {
  const router = useRouter();
  const [user, setUser] = useState<AuthenticatedUserType | null>(defaultProvider.user);
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading);

  // Initialize auth state from cookies
  const initAuth = async () => {
    setLoading(true);
    const storedUser = initAuthFromCookies();
    if (storedUser) {
      setUser(storedUser);
    }
    setLoading(false);
  };

  // Fetch profile data
  const profileApi = async () => {
    await initAuth();
  };

  // Initialize auth on component mount
  useEffect(() => {
    initAuth();
  }, []);
  const values: AuthContextType = {
    isAuthenticated: !!user && isAuthenticated(),
    user,
    loading,
    setUser,
    setLoading,
    login: (params: LoginParamsType) => handleLogin(params, setUser, router),
    logout: () => handleLogout(setUser, router),
    profileApi
  };
  return <AuthContext.Provider value={values} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="AuthContext.tsx">{children}</AuthContext.Provider>;
};