'use client';

import React, { Suspense } from 'react';
import Sidebar from '../../app/sidebar/sidebar';
import ContentComponent from './ContentComponent';
import type { ContentProps } from './ContentComponent';
import HomeContent from './HomeContent';
import { MantineLoader } from '@whatsnxt/core-ui';
import { Box, Grid, GridCol } from '@mantine/core';
function Content(props: ContentProps) {
  const {
    type = 'both'
  } = props;
  return <Suspense fallback={<MantineLoader />} data-sentry-element="Suspense" data-sentry-component="Content" data-sentry-source-file="Content.tsx">
      <Box pt={'xl'} pb={'xl'} data-sentry-element="Box" data-sentry-source-file="Content.tsx">
        <Grid data-sentry-element="Grid" data-sentry-source-file="Content.tsx">
          <GridCol span={{
          base: 12,
          md: 9
        }} data-sentry-element="GridCol" data-sentry-source-file="Content.tsx">
            {type === 'both' ? <HomeContent type={type} /> : <ContentComponent type={type} />}
          </GridCol>
          <GridCol span={{
          base: 12,
          md: 3
        }} data-sentry-element="GridCol" data-sentry-source-file="Content.tsx">
            <Sidebar data-sentry-element="Sidebar" data-sentry-source-file="Content.tsx" />
          </GridCol>
        </Grid>
      </Box>
    </Suspense>;
}
export default Content;