import React from 'react';
import { Text, Container, ActionIcon, Group, Anchor, Box, List } from '@mantine/core';
import classes from './Footer.module.css';
import { Logo } from '../Logo';
import { IconBrandInstagram, IconBrandLinkedin, IconBrandTwitter, IconBrandFacebook, IconMail, IconMapPin, IconPhoneCall, IconCopyright } from '@tabler/icons-react';
export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const socialMediaPlatforms = [{
    icon: IconBrandFacebook,
    url: 'https://www.facebook.com'
  }, {
    icon: IconBrandTwitter,
    url: 'https://www.twitter.com'
  }, {
    icon: IconBrandInstagram,
    url: 'https://www.instagram.com'
  }, {
    icon: IconBrandLinkedin,
    url: 'https://www.linkedin.com'
  }];

  // Data for footer sections
  const footerSections = [{
    title: 'Explore',
    links: [{
      text: 'Home',
      link: '/'
    }, {
      text: 'About',
      link: '/about'
    }, {
      text: 'Courses',
      link: '/courses'
    }, {
      text: 'Contact',
      link: '/contact'
    }]
  }, {
    title: 'Resources',
    links: [{
      text: 'Student Success',
      link: '/terms-of-service'
    }, {
      text: 'For Business',
      link: '/terms-of-service'
    }, {
      text: 'Team Plans',
      link: '/terms-of-service'
    }]
  }, {
    title: 'Address',
    contactInfo: [{
      icon: IconMapPin,
      text: 'Hyderabad, India',
      link: 'https://maps.app.goo.gl/2o5hu5yMSc78Tz4W9'
    }, {
      icon: IconPhoneCall,
      text: '+91 8790385964',
      link: 'tel:+44587154756'
    }, {
      icon: IconMail,
      text: 'info@whatsnxt.in',
      link: 'mailto:info@whatsnxt.in'
    }]
  }];
  return <Box className={classes['footer-area']} data-sentry-element="Box" data-sentry-component="Footer" data-sentry-source-file="index.tsx">
      <Container className="container" data-sentry-element="Container" data-sentry-source-file="index.tsx">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6 px-4 md-1">
            <div className={classes['single-footer-widget']}>
              <Logo color="white" className="w-75 mb-3" data-sentry-element="Logo" data-sentry-source-file="index.tsx" />
              <Text data-sentry-element="Text" data-sentry-source-file="index.tsx">
                Working to bring significant changes in online-based learning by
                doing extensive research for course curriculum preparation,
                student engagements, and looking forward to the flexible
                education!
              </Text>
              <Group gap={10} className={classes.social} justify="flex-start" wrap="nowrap" data-sentry-element="Group" data-sentry-source-file="index.tsx">
                {socialMediaPlatforms.map((smp, i) => <ActionIcon key={i} size="lg" color="white" variant="subtle" className={classes.socialIcon} component="a" href={smp.url} target="_blank" rel="noopener noreferrer">
                    <smp.icon size={16} />
                  </ActionIcon>)}
              </Group>
            </div>
          </div>
          {footerSections.map((section, index) => <Box key={index} className={`col-lg-${section.title === 'Address' ? '4' : '2'} col-md-6 col-sm-6 pt-4 pt-lg-0`}>
              <div className={classes['single-footer-widget']}>
                {section.title === 'Address' ? <>
                    <Text size="xl" fw={800}>{section.title}</Text>
                    <List className={classes['footer-contact-info']}>
                      {section.contactInfo.map((info, i) => <List.Item key={i} className={classes['list-item']}>
                          <info.icon size={16} className={classes['contact-icon']} />
                          <Anchor className="mx-4 text-decoration-none" href={info.link} target="_blank">
                            {info.text}
                          </Anchor>
                        </List.Item>)}
                    </List>
                  </> : <>
                    <Text size="xl" fw={800}>{section.title}</Text>
                    <List className={classes['footer-links-list']}>
                      {section.links?.map((link, i) => <List.Item key={i}>
                          <Anchor className="text-decoration-none" href={link.link}>{link.text}</Anchor>
                        </List.Item>)}
                    </List>
                  </>}
              </div>
            </Box>)}
        </div>
      </Container>

      <Box className={classes['footer-bottom-area']} data-sentry-element="Box" data-sentry-source-file="index.tsx">
        <div className="container">
          <div className="row align-items-center py-5">
            <div className="col-lg-6 col-md-6">
              <Text className="d-flex align-items-center gap-1" data-sentry-element="Text" data-sentry-source-file="index.tsx">
                <IconCopyright size={18} data-sentry-element="IconCopyright" data-sentry-source-file="index.tsx" />
                {currentYear} whatsnxt
                <Anchor target="_blank" href="https://www.whatsnxt.in/" className="text-decoration-none" data-sentry-element="Anchor" data-sentry-source-file="index.tsx">
                  Copyright reserved
                </Anchor>
              </Text>
            </div>

            <div className="col-lg-6 col-md-6">
              <List data-sentry-element="List" data-sentry-source-file="index.tsx">
                <List.Item data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                  <Anchor className="text-decoration-none" href="/privacy-policy" data-sentry-element="Anchor" data-sentry-source-file="index.tsx">Privacy Policy</Anchor>
                </List.Item>
                <List.Item data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                  <Anchor className="text-decoration-none" href="/terms-of-service" data-sentry-element="Anchor" data-sentry-source-file="index.tsx">Terms & Conditions</Anchor>
                </List.Item>
              </List>
            </div>
          </div>
        </div>
      </Box>

      <div className={classes.lines}>
        <div className={classes.line}></div>
        <div className={classes.line}></div>
        <div className={classes.line}></div>
      </div>
    </Box>;
};