import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/blog-mfe/src/app/ClientLayout.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blog-mfe/src/styles/bootstrap.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blog-mfe/src/styles/style.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blog-mfe/src/styles/history.css");
;
import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/vercel/path0/node_modules/.pnpm/@mantine+core@7.15.1_@mantine+hooks@7.15.1_react@19.0.0__@types+react@19.0.2_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+core@7.15.1_@mantine+hooks@7.15.1_react@19.0.0__@types+react@19.0.2_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+nprogress@7.15.1_@mantine+core@7.15.1_@mantine+hooks@7.15.1_react@19.0.0__@types+rea_afo252gjks4gism525qb4rkjba/node_modules/@mantine/nprogress/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mantine+notifications@7.15.1_@mantine+core@7.15.1_@mantine+hooks@7.15.1_react@19.0.0__@types_yira4jzc5xwns5h7arl4cxthki/node_modules/@mantine/notifications/styles.css");
