import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ContentAPI } from '../../api';

const initialState = {
  articles: [
    {
      _id: '',
      title: '',
      slug: '',
      description: '',
      categoryName: '',
      imageUrl: '',
      author: '',
      updatedAt: '',
      tutorial: false,
    },
  ],
  currentTag: null,
  totalCount: 0,
  loading: true,
  error: '',
};

export const getPosts: any = createAsyncThunk(
  'blog/posts',
  async ({ start = 1, limit = 10, type = 'both' }: any) => {
    return await ContentAPI.getPosts(start, limit, type);
  },
);

export const getPostsByCategory = createAsyncThunk(
  'category/posts/category',
  async (categoryName: string) =>
    await ContentAPI.getPostsByCategory(categoryName),
);

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setSelectTag: (state, action) => {
      state.currentTag = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getPosts.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(
        getPosts.fulfilled,
        (
          state: { totalCount: any, articles: any; loading: boolean; error: string },
          action: { payload: any },
        ) => {
          state.articles = action.payload?.posts || [];
          state.totalCount = action.payload?.totalRecords || 0
          state.loading = false;
          state.error = '';
        },
      )
      .addCase(
        getPosts.rejected,
        (
          state: { loading: boolean; articles: never[]; error: any },
          action: { error: { message: any } },
        ) => {
          state.loading = false;
          state.articles = [];
          state.error = action.error.message;
        },
      )
      .addCase(getPostsByCategory.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(
        getPostsByCategory.fulfilled,
        (
          state: { articles: any; loading: boolean; error: string },
          action: { payload: any },
        ) => {
          state.articles = action.payload as any;
          state.loading = false;
          state.error = '';
        },
      )
      .addCase(
        getPostsByCategory.rejected,
        (
          state: { loading: boolean; articles: never[]; error: any },
          action: { error: { message: any } },
        ) => {
          state.loading = false;
          state.articles = [];
          state.error = action.error.message;
        },
      );
  },
});

export const { setSelectTag } = contentSlice.actions;
export const contentReducer = contentSlice.reducer;
