import React, { FC } from 'react';
import { Anchor } from '@mantine/core';
import styles from './SocialLinks.module.css';
import { IconBrandFacebook, IconBrandInstagram, IconBrandLinkedin, IconBrandTwitter } from '@tabler/icons-react';
type SocialLinksProps = {
  // Define your component props here
  data: any;
};
export const SocialLinks: FC<SocialLinksProps> = ({
  data
}) => {
  return <ul className={`${styles['social-link']} ${styles['social-link']}`} data-sentry-component="SocialLinks" data-sentry-source-file="SocialLinks.tsx">
      <li>
        <Anchor href={data?.userId?.fb_url || '#'} className="d-block" target="_blank" data-sentry-element="Anchor" data-sentry-source-file="SocialLinks.tsx">
          <IconBrandFacebook size={16} data-sentry-element="IconBrandFacebook" data-sentry-source-file="SocialLinks.tsx" />
        </Anchor>
      </li>
      <li>
        <Anchor href={data?.userId?.tw_url || '#'} className="d-block" target="_blank" data-sentry-element="Anchor" data-sentry-source-file="SocialLinks.tsx">
          <IconBrandTwitter size={16} data-sentry-element="IconBrandTwitter" data-sentry-source-file="SocialLinks.tsx" />
        </Anchor>
      </li>
      <li>
        <Anchor href={data?.userId?.insta_url || '#'} className="d-block" target="_blank" data-sentry-element="Anchor" data-sentry-source-file="SocialLinks.tsx">
          <IconBrandInstagram size={16} data-sentry-element="IconBrandInstagram" data-sentry-source-file="SocialLinks.tsx" />
        </Anchor>
      </li>
      <li>
        <Anchor href={data?.userId?.in_url || '#'} className="d-block" target="_blank" data-sentry-element="Anchor" data-sentry-source-file="SocialLinks.tsx">
          <IconBrandLinkedin size={16} data-sentry-element="IconBrandLinkedin" data-sentry-source-file="SocialLinks.tsx" />
        </Anchor>
      </li>
    </ul>;
};