import React from 'react';
import styles from './ErrorTemplate.module.css';
function ErrorTemplate() {
  return <>
      {/* <h4>404 - Page Not Found</h4> */}

      <section className={styles.page_404}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-10 col-sm-offset-1  text-center">
                <div className={styles.four_zero_four_bg}>
                  <h1 className="text-center ">404</h1>
                </div>

                <div className={styles.constant_box_404}>
                  <h3 className={styles.h2}>Look like you are lost</h3>
                  <p>the page you are looking for is not available!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>;
}
export default ErrorTemplate;