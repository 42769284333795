import api from '@whatsnxt/core-util/src/ApiClient/xiorInstance';

const GET_POPULAR_QUERY = (isPrivate: boolean) => `
  query ${isPrivate ? 'GetPrivPopular' : 'GetPubPopular'} {
    ${isPrivate ? 'privPopularPosts' : 'pubPopularPosts'} {
      _id
      title
      slug
      description
      categoryId
      categoryName
      published
      listed
      imageUrl
    }
  }
`;

export const SidebarAPI = {
  getPopular: async function () {
    const { data } = await api.request({
      data: {
        query: GET_POPULAR_QUERY,
      },
    }) as any;

    return data.data
      ? data.data.privPopularPosts || data.data.pubPopularPosts
      : [];
  },
};

