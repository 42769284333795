import React from 'react';
import { Anchor, Card, Text } from '@mantine/core';
import Link from 'next/link';
export const CardComponent = ({
  image,
  title,
  link,
  children
}: ICardComponentProps) => {
  return <Card shadow="sm" padding="lg" radius="md" withBorder data-sentry-element="Card" data-sentry-component="CardComponent" data-sentry-source-file="CardComponent.tsx">
      <Card.Section data-sentry-element="unknown" data-sentry-source-file="CardComponent.tsx">
        <Anchor component={Link} href={link} data-sentry-element="Anchor" data-sentry-source-file="CardComponent.tsx">
          {image}
        </Anchor>
      </Card.Section>
      <Text fw={600} className="my-3" data-sentry-element="Text" data-sentry-source-file="CardComponent.tsx">
        <Anchor component={Link} href={link} data-sentry-element="Anchor" data-sentry-source-file="CardComponent.tsx">
          {title}
        </Anchor>
      </Text>
      {children}
    </Card>;
};
interface ICardComponentProps {
  title: string;
  link: string;
  image: React.ReactNode;
  children: React.ReactNode;
}