import React, { JSX } from 'react';
import { NextSeo } from 'next-seo';
import { PageSeoProps } from './Types';
export const generateSeoTags = ({
  metaTitle,
  metaDesc,
  ogTitle,
  ogDesc,
  ogImage
}: PageSeoProps): JSX.Element => {
  const seoImages = ogImage ? {
    images: [{
      url: ogImage.url,
      width: ogImage.width,
      height: ogImage.height,
      alt: ogImage.description,
      type: ogImage.contentType
    }]
  } : {};
  return <NextSeo title={metaTitle} description={metaDesc} openGraph={{
    title: ogTitle,
    description: ogDesc,
    ...seoImages
  }} data-sentry-element="NextSeo" data-sentry-component="generateSeoTags" data-sentry-source-file="GenerateMetaTags.tsx" />;
};