import { Affix, Flex, Transition } from '@mantine/core';
import { useWindowScroll } from '@mantine/hooks';
import styles from './GoTop.module.css';
import React from 'react';
import { IconChevronUp } from '@tabler/icons-react';
export const GoTop = () => {
  const [scroll, scrollTo] = useWindowScroll();
  return <Affix position={{
    bottom: 20,
    right: 20
  }} data-sentry-element="Affix" data-sentry-component="GoTop" data-sentry-source-file="GoTop.tsx">
      <Transition transition="slide-up" mounted={scroll.y > 0} data-sentry-element="Transition" data-sentry-source-file="GoTop.tsx">
        {transitionStyles => <Flex style={transitionStyles} justify={'center'} align={'center'} onClick={() => scrollTo({
        y: 0
      })} className={`${styles['go-top']} ${styles['active']}`}>
            <IconChevronUp size={16} />
          </Flex>}
      </Transition>
    </Affix>;
};