import React, { useMemo } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonCardContent } from '@whatsnxt/core-ui';
import { InfiniteScrollComponent } from '@whatsnxt/core-util';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Flex } from '@mantine/core';
import TutorialCard from '../Cards/Tutorial';
import BlogCard from '../Cards/Blog';
import { ContentAPI } from '../../api';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';

/* eslint-disable-next-line */
type ContentType = "blog" | "tutorial" | "both";
/* eslint-disable-next-line */
interface ContentProps {
  type: ContentType;
}
interface Post {
  id: string;
  title: string;
  tutorial?: any; // Replace 'any' with the actual type if known
  blog?: any; // Replace 'any' with the actual type if known
}
interface PostPage {
  totalRecords: number;
  posts: Post[];
}
function HomeContent(props: ContentProps) {
  const {
    type
  } = props;
  const currentSelectedTag = useSelector((store: RootState) => store.content.currentTag);
  const currentArticles = useSelector((store: RootState) => store.content.articles);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching
  } = useInfiniteQuery<PostPage>({
    queryKey: ['/blog-tutorial/lists'],
    queryFn: ({
      pageParam = 1
    }: any) => ContentAPI.getPosts(pageParam, 10, type),
    getNextPageParam: (lastPage, allPages) => {
      const {
        totalRecords
      } = lastPage;
      const nextOffset = allPages.length * 10;
      return nextOffset < totalRecords ? allPages.length + 1 : undefined;
    },
    initialPageParam: undefined
  });
  const flatData = useMemo(() => currentSelectedTag ? currentArticles : data ? data?.pages?.flatMap((item: PostPage) => item.posts) : [], [currentArticles, currentSelectedTag, data]);
  const onInfiniteScroll = () => {
    fetchNextPage();
  };
  return <>
            <InfiniteScrollComponent isLoading={isFetching} isScrollCompleted={!hasNextPage && !currentSelectedTag} onViewPortCallback={onInfiniteScroll} data-sentry-element="InfiniteScrollComponent" data-sentry-source-file="HomeContent.tsx">
                <Flex justify='center' gap='xl' wrap='wrap' px='sm' data-sentry-element="Flex" data-sentry-source-file="HomeContent.tsx">
                    {isLoading ? <SkeletonCardContent /> : flatData && flatData.length > 0 && flatData.map((item: any, i) => <div key={i}>
                                {item?.tutorial ? <TutorialCard tutorial={item} /> : <BlogCard blog={item} />}
                            </div>)}
                </Flex>
            </InfiniteScrollComponent>
        </>;
}
export default HomeContent;