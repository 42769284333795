export const GET_CATEGORIES_QUERY = (isPrivate = false) => `
  query ${isPrivate ? 'GetPrivCategories' : 'GetPubCategories'} {
    ${isPrivate ? 'privCategories' : 'pubCategories'} {
      _id
      categoryName
      subcategories {
        name
        subcategories {
          name
        }
      }
    }
  }
`;

export const GET_CATEGORIES_COUNT_QUERY = (isPrivate = false) => `
  query ${isPrivate ? 'GetPrivCategoriesCount' : 'GetPubCategoriesCount'} {
    ${isPrivate ? 'privCategoriesCount' : 'pubCategoriesCount'} {
      categoryName
      categoryId
      count
    }
  }
`;

export const EDIT_CATEGORY_MUTATION = () => `
  mutation EditCategory(
    $categoryId: String!,
    $categoryName: String!
  ) {
    editCategory(
      categoryId: $categoryId,
      categoryName: $categoryName
    ) {
      _id
      categoryName
    }
  }
`;
