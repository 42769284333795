import { getCookie } from 'cookies-next';
import { manageWorker } from '../../../utils/worker/manageWorker';
import { addUploadedAsset, removeAllUploadedAsset, removeUploadedAssetsList, updateUploadedAssets } from '../../../utils/worker/workerWithLocalStorage';
import { notifications } from '@mantine/notifications';

const replaceImageLinksOnContentPreview = async ({ file, timestamp, editor, tempUrl, imageUrl, setProgress }: any) => {
    // replace cloudinary links on content 
    const { state } = editor.view;
    const { doc, tr } = state;

    // This code will replace the temporary created URL with the new Cloudinary link in the editor 
    doc.descendants((node: any, position: any) => {
        if (node.attrs.src === tempUrl) {
            tr.setNodeMarkup(position, undefined, { ...node.attrs, src: imageUrl });
        }
    });

    // Create a Promise to handle the dispatch process
    const dispatchPromise = new Promise<void>((resolve, reject) => {
        try {
            editor.view.dispatch(tr);
            resolve();
        } catch (error) {
            reject(error);
        }
    });

    // Await the completion of the dispatchPromise before proceeding to setProgress
    dispatchPromise.then(() => {
        if (setProgress) {
            // After the successful replacement of the asset in the editor, close the progress bar
            setProgress({ fileName: file?.name, timestamp, progress: 100, isCompleted: true });
        }
    });


}

const removeTempImageFromEditor = ({ editor, tempUrl }: any) => {
    // remove temporary image from editor
    const { state } = editor.view;
    const { doc, tr } = state;
    // this code remove image from the editor that match with tempUrl
    doc.descendants((node: any, position: any) => {
        if (node.attrs.src === tempUrl) {
            tr.delete(position, position + node.nodeSize);
        }
    });

    editor.view.dispatch(tr);
}


export const uploadDataWebWorker = async ({ file, tempUrl, editor, folder, type, setProgress }: any) => {
    // create a new worker with upload worker script 
      // @ts-ignore
    const worker = new Worker(new URL('../../../utils/worker/uploadWorker', import.meta.url));
    // upload assets using webworker
    try {
        const result = await manageWorker(worker, {
            file,
            fileKeyName: 'file',
            folder,
            type,
        }, setProgress) as {
            secure_url: string; public_id: string; timestamp: number; resource_type: string
        };


        if (result) {
            const imageUrl = result?.secure_url;
            if (result?.public_id) {
                // save public publicId with type to local storage
                addUploadedAsset(result?.public_id, result.resource_type)
            }

            // replace uploaded new cloudinary link on editor 
            replaceImageLinksOnContentPreview({ setProgress, timestamp: result?.timestamp, file, tempUrl, imageUrl, editor })
        }
    } catch (error) {
        removeTempImageFromEditor({ editor, tempUrl });
        notifications.show({
            title: 'Upload Failed error',
            message: 'Asset failed to upload',
            color: 'red'
        });
    }

}

export const deleteDataWebWorker = async ({ assetsList }: any) => {
    // @ts-ignore
    const storedToken = getCookie(process.env.NEXT_PUBLIC_COOKIES_ACCESS_TOKEN);
    // create a new worker with upload worker script 
      // @ts-ignore
    const worker = new Worker(new URL('../../../utils/worker/deleteWorker', import.meta.url));
    // delete assets using webworker
    try {
        await manageWorker(worker, {
            assetsList,
            accessToken:storedToken
        })
        await removeAllUploadedAsset()

    } catch (error) {
        console.log(error)
    }

}


const extractCloudinaryLinksFromContent = (content: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const links: string[] = [];

    const elements = doc.querySelectorAll('img, audio, video, a'); // Adjust the selectors based on the tags used for audio, video, and files

    elements.forEach(el => {
        const src = el.getAttribute('src');
        const href = el.getAttribute('href');
        if (src && src.includes('cloudinary')) {
            links.push(src);
        }
        if (href && href.includes('cloudinary')) {
            links.push(href);
        }
    });

    return links;
};

const extractPublicId = (link: string) => {
    // Split the URL by slashes and get the second last part
    const parts = link.split('/');
    const lastPart = parts.slice(-3).join('/');

    // Check if the link contains "raw" and keep the extension if it does
    if (link.includes('raw')) {
        return lastPart;
    }

    // Remove the file extension from the last part for other media types
    return lastPart.split('.')[0];
}

const extractPublicIdsFromLinks = (links: string[]) => {
    // extract from this link > https://res.cloudinary.com/cloudinary999/image/upload/v1722249986/whatsnxt-blog/66a4888e70914d68e0351c90/download.jpg
    return links.map(link => extractPublicId(link));
};

const mediaTypes = ['image', 'video', 'raw'];

const extractPublicIdsAndTypeFromLinks = (links: string[]) => {

    return links.map(link => {
        const parts = link.split('/');
        const publicId = extractPublicId(link)
        const type = parts.find(part => mediaTypes.includes(part)) || 'image'; // Get the type (e.g., video, image)

        return {
            publicId,
            type
        };
    });
};

export const cloudinaryAssetsUploadCleanup = ({ content }: any) => {
    const cloudinaryLinksFromContent = extractCloudinaryLinksFromContent(content);
    const usedPublicIdsInEditor = extractPublicIdsFromLinks([...cloudinaryLinksFromContent]);
    removeUploadedAssetsList(usedPublicIdsInEditor)

    return cloudinaryLinksFromContent ? extractPublicIdsAndTypeFromLinks([...cloudinaryLinksFromContent]) : []
}


export const cloudinaryAssetsUploadCleanupForUpdate = ({ oldContent, newContent }: any) => {
    const cloudinaryLinksNew = newContent ? extractCloudinaryLinksFromContent(newContent) : null;
    const cloudinaryLinksPrev = oldContent ? extractCloudinaryLinksFromContent(oldContent) : null;

    const usedPublicIdsInNewEditor = cloudinaryLinksNew ? extractPublicIdsFromLinks([...cloudinaryLinksNew]) : [];
    const usedPublicIdsInPrevEditor = cloudinaryLinksPrev ? extractPublicIdsAndTypeFromLinks([...cloudinaryLinksPrev]) : [];

    removeUploadedAssetsList(usedPublicIdsInNewEditor)
    // get the public IDs that are in the old editor but not in the updated editor
    const publicIdsNotInUpdatedEditor = usedPublicIdsInPrevEditor.filter(({publicId }) => !usedPublicIdsInNewEditor.includes(publicId));
    // store it to on local storage so on cleanup it will be removed 
    updateUploadedAssets(publicIdsNotInUpdatedEditor)

    return cloudinaryLinksNew ? extractPublicIdsAndTypeFromLinks([...cloudinaryLinksNew]) : []
}
