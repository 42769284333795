import xior from 'xior';
import { AppLogger } from '../AppLogger';

const appLogger = new AppLogger();

// initializing the xior instance with custom configs
const api: any = xior.create({
  baseURL: process.env.BFF_HOST,
  headers: {
    ['X-Service-Identifier']: 'whatsnxt.in',
  },
});

// defining a custom error handler for all APIs
const errorHandler = async (error: any) => {
  const statusCodes = [400, 401, 409, 406, 500];
  const statusCode = error.response?.status;

  try {
    // logging only errors that are not 401
    if (statusCode && statusCode !== 401) {
      appLogger.info(`ErrorHandler:: message: ${JSON.stringify(error.response?.data?.message)} code: ${statusCode}`);
    }

    if (statusCode === 401 || statusCode === 404) {
      appLogger.warn(`Unauthorized or Not Found:: message: ${error.response.statusText} code: ${statusCode}`);
      throw new Error('unauthorized');
    }

    if (statusCodes.includes(statusCode)) {
      appLogger.info(`API Error:: message: ${JSON.stringify(error.response?.data?.message)} code: ${statusCode}`);
    }

    return Promise.reject(error);
  } catch (e) {
    appLogger.info(`ErrorHandler Exception:: message: ${e}`);
    return Promise.reject(e);
  }
};

api.interceptors.request.use(
  (config: any) => {
    const controller = new AbortController();
    config.signal = controller.signal;

    // Automatically abort the request after 10 seconds
    const timeoutId = setTimeout(() => {
      controller.abort();
    }, 10000);

    // Store the timeoutId in the config to clear later
    config.timeoutId = timeoutId;
    return config;
  },
  (error: any) => {
    console.log('🚀 ~ xior interceptor global error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor to clear the timeout and handle errors using errorHandler
api.interceptors.response.use(
  (response: any) => {
    // Clear the timeout when the request succeeds
    if (response.config?.timeoutId) {
      clearTimeout(response.config.timeoutId);
    }
    return response;
  },
  async (error: any) => {
    // Clear the timeout if the request fails or is aborted
    if (error.config?.timeoutId) {
      clearTimeout(error.config.timeoutId);
    }

    // Check for an AbortError to handle request cancellation
    if (error.name === 'AbortError') {
      console.error('Request was aborted due to timeout');
    }

    // Call the custom errorHandler for further handling of the error
    return errorHandler(error);
  }
);

export default api;
