import { Button, Text, Title } from '@mantine/core';
import { IMemoStore } from './interfaces';
import './tags.module.css';
import React from 'react';
interface Props {
  categoryStore: IMemoStore;
  sidebarStore: any;
  onClick: (value: string) => void;
}
export default function PopularTag(props: Props) {
  const {
    categoryStore,
    sidebarStore,
    onClick
  } = props;

  // Check if there is at least one listed item
  const hasListedItem = sidebarStore.articles.some((item: {
    listed: boolean;
  }) => item.listed);
  return <div className='widget widget_tag_cloud' data-sentry-component="PopularTag" data-sentry-source-file="index.tsx">
            {hasListedItem && <Title order={4} mb={'sm'}>Popular Tags</Title>}
            {hasListedItem && <div>
                    {categoryStore.categories.map((item, index) => {
        if (item.count > 0 && sidebarStore.articles[index].listed) {
          return <Button key={index} mr={5} mb={5} onClick={() => onClick(item.categoryName)}>
                                    {item.categoryName}
                                </Button>;
        }
        return null;
      })}
                </div>}
        </div>;
}