'use client';

import React, { FC } from 'react';
import styles from './LoadingSpinner.module.css';
type LoadingSpinnerProps = {
  // Define your component props here
};
export const LoadingSpinner: FC<LoadingSpinnerProps> = () => {
  return <div className={styles.preloader} data-sentry-component="LoadingSpinner" data-sentry-source-file="LoadingSpinner.tsx">
      <div className={styles.loader}>
        <div className={styles['loadingio-spinner-reload']}>
          <div className={styles.ldio}>
            <div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>;
};