'use client';

import React from 'react';
import { useRouter } from 'next/router';
import { Select, ComboboxData } from '@mantine/core';
export const SortByComponent = () => {
  const router = useRouter();
  const sortCourses = (sort: string | null) => {
    router.push({
      query: {
        ...router.query,
        sort: sort ?? ''
      }
    });
  };
  const opts: ComboboxData = [{
    value: 'popularity',
    label: 'Popularity'
  }, {
    value: 'latest',
    label: 'Latest'
  }, {
    value: 'low-high',
    label: 'Price: low to high'
  }, {
    value: 'high-low',
    label: 'Price: high to low'
  }];
  return <div className="col-lg-4 col-md-6" data-sentry-component="SortByComponent" data-sentry-source-file="SortByComponent.tsx">
      <Select size="md" radius="md" placeholder="Sort by" clearable={true} data={opts} onChange={sortCourses} data-sentry-element="Select" data-sentry-source-file="SortByComponent.tsx" />
    </div>;
};