import { categoryReducer } from './slices/categorySlice';
import { sidebarReducer } from './slices/sidebarSlice';
import { configureStore } from '@reduxjs/toolkit';
import { contentReducer } from './slices/contentSlice';
import { createWrapper } from 'next-redux-wrapper';
import { imageReducer } from './slices/imageSlice';
import { authReducer } from './slices/authSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    content: contentReducer,
    sidebar: sidebarReducer,
    category: categoryReducer,
    image: imageReducer,
  },
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const makeStore = () => store;
const wrapper = createWrapper(makeStore);
