import React from 'react';
import styles from './MainBanner.module.css';
export const MainBanner = () => {
  return <div className="container pt-100" data-sentry-component="MainBanner" data-sentry-source-file="MainBanner.tsx">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-12 col-md-12">
            <div className={styles['main-banner-content-style-two']}>
              <h1 className="text-center">Leading Online-Learning Provider</h1>
            </div>
          </div>
        </div>
      </div>
    </div>;
};