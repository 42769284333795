import { checkGraphqlResponse } from '../utils/commonHelper';
import api from '@whatsnxt/core-util/src/ApiClient/xiorInstance';
import { RESET_PASS_MUTATION, SET_PASS_MUTATION, UPDATE_USER_TYPE_MUTATION } from './gqlQueries/userQuery';

export const UserAPI = {
  resetPasswordRequest: async function (payload: any) {
    const { data } = await api.request({
      data: {
        query: RESET_PASS_MUTATION,
        variables: { email: payload.email },
      },
    });
    return checkGraphqlResponse(data).resetPasswordRequest
  },
  resetPassword: async function (payload: any) {
    const { data } = await api.request({
      data: {
        query: SET_PASS_MUTATION,
        variables: {
          userId: payload.id,
          token: payload.token,
          email: payload.email,
          password: payload.password,
        },
      },
    });
    return checkGraphqlResponse(data).resetPassword
  },
  updateUserType: async function (payload: any) {
    const { data } = await api.request({
      data: {
        query: UPDATE_USER_TYPE_MUTATION,
        variables: {
          creator: payload.creator,
          email: payload.email
        },
      },
    });
    return checkGraphqlResponse(data).updateUserType
  }
};
