import axiosClient from './AxiosClient';

const put = async (url: string, data: unknown): Promise<any> => {
    const response = await axiosClient.put(url, data);
    return response;
};
const post = async (url: string, data: unknown): Promise<any> => {
    const response = await axiosClient.post(url, data);
    return response;
};
const get = async (url: string): Promise<any> => {
    const response = await axiosClient.get(url);
    return response;
};
const patch = async (url: string, data: unknown): Promise<any> => {
    const response = await axiosClient.patch(url, data);
    return response;
};
export const BFFClient = {
    put: put,
    post: post,
    get: get,
    patch: patch
};