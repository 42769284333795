import { NextSeo } from 'next-seo';
import { FC } from 'react';
import './NextSeoComponent.css';
type NextSeoComponentProps = {
  title: string;
};
export const NextSeoComponent: FC<NextSeoComponentProps> = ({
  title
}) => {
  const generateMetaTags = () => {
    return {
      title: title,
      description: 'Interesting blogs and tutorials',
      canonical: 'https://www.blog.whatsnxt.in',
      openGraph: {
        url: 'https://www.blog.whatsnxt.in',
        title: title,
        description: 'Interesting blogs and tutorials',
        siteName: 'https://blog.whatsnxt.in'
      }
    };
  };

  //@ts-ignore
  return <NextSeo {...generateMetaTags()} data-sentry-element="NextSeo" data-sentry-component="NextSeoComponent" data-sentry-source-file="NextSeoComponent.tsx" />;
};
export default NextSeoComponent;