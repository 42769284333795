import React from 'react';
import SidebarPopularTags from '../sidebar-category/sidebar-category';
import SidebarPost from '../sidebar-post/sidebar-post';
function Sidebar() {
  return <div data-sentry-component="Sidebar" data-sentry-source-file="sidebar.tsx">
      {/* <SidebarSearch></SidebarSearch> */}

      <SidebarPost data-sentry-element="SidebarPost" data-sentry-source-file="sidebar.tsx"></SidebarPost>

      <SidebarPopularTags data-sentry-element="SidebarPopularTags" data-sentry-source-file="sidebar.tsx"></SidebarPopularTags>
    </div>;
}
export default Sidebar;