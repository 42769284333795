import xior from 'xior';
import Cookie from 'js-cookie'

const baseURL = process.env.NEXT_PUBLIC_GRAPHQL_URL;

const api: any = xior.create({
  baseURL,
  method: 'POST',
  withCredentials: true,
});

function isWebWorker(): boolean {
    // @ts-ignore
    return typeof Worker !== 'undefined' && typeof importScripts === 'function';
}

// Request interceptor for including the authorization token
api.interceptors.request.use(
  (config: any) => {
    // @ts-ignore
    const token = config.data?.accessToken ? config.data?.accessToken : Cookie.get(process.env.NEXT_PUBLIC_COOKIES_ACCESS_TOKEN); // Retrieve token from cookie

    if (isWebWorker() && config?.data?.accessToken) {
      // If running inside a Web Worker, manually include the token in the headers
      config.headers['Cookie'] = `${process.env.NEXT_PUBLIC_COOKIES_ACCESS_TOKEN}=${token}; ${config.headers['Cookie'] || ''}`;
      // Remove the token from the payload
      if (config.data?.accessToken) {
        delete config.data.accessToken;
      }
    }
    // If the query is a function, execute it with the token or other parameter
    if (typeof config.data?.query === 'function') {
      config.data.query = config.data.query(token);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 Unauthorized errors in GraphQL responses
const handleGraphQLError = (response: any): any => {
  // set 403 status based condition
  if (response.data && response.data.errors) {
    const errors = response.data.errors;
    const unauthorizedError = errors.find(
      (error: any) => error.message === 'Unauthorized'
    );

    if (unauthorizedError) {
      //clear token and user
      // @ts-ignore
      Cookie.remove(process.env.NEXT_PUBLIC_COOKIES_ACCESS_TOKEN);
      // @ts-ignore
      Cookie.remove(process.env.NEXT_PUBLIC_COOKIES_USER);
      // Redirect to the login page
      window.location.href = '/auth/authentication'; // Replace with your login page URL
      return response; // Exit function if redirection is happening
    }
    return response;
  }

  return response; // Return the response if no error is found
};

// Registering the error handler to the "api" xior instance
api.interceptors.request.use((config) => {
  return config;
});

// Add the response interceptor to handle GraphQL errors
api.interceptors.response.use(handleGraphQLError, (error) => {
  return Promise.reject(error);
});

export default api;
