import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Anchor, Divider, LoadingOverlay } from '@mantine/core';
import { Card, Text, Badge, Group } from '@mantine/core';
function BlogCard({
  blog
}: IBlogCard) {
  const [loading, setLoading] = useState(false);
  const handleNavigation = () => {
    setLoading(true);
  };
  return <>
      {/* Overlay Loader */}
      {loading && <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{
      radius: "sm",
      blur: 2
    }} />}

      {/* Blog Card */}
      {blog && blog.listed && <Anchor component={Link} href={`/content/${blog.slug}`}>
          <Card padding="xs" radius="md" withBorder onClick={handleNavigation}>
            <Card.Section>
              <Image src={blog.imageUrl} alt={blog.title} width={300} height={200} />
            </Card.Section>
            <Divider />
            <Group justify="space-between" mt="md" mb="sm">
              <Badge color="pink">{blog.categoryName}</Badge>
            </Group>
            <Text size="md" mb="sm" lineClamp={2}>
              {blog.title}
            </Text>
          </Card>
        </Anchor>}
    </>;
}
interface IBlogCard {
  blog: {
    title: string;
    slug: string;
    categoryName: string;
    imageUrl: string;
    listed: boolean;
  };
}
export default BlogCard;