import { LoadingOverlay as MantineOverlay, Box } from '@mantine/core';
import React from 'react';
interface Props {
  children: React.ReactNode;
  visible: boolean;
}
const LoadingOverlay = ({
  children,
  visible
}: Props) => {
  return <Box pos="relative" data-sentry-element="Box" data-sentry-component="LoadingOverlay" data-sentry-source-file="index.tsx">
            <MantineOverlay visible={visible} zIndex={1000} overlayProps={{
      radius: 'sm',
      blur: 2
    }} data-sentry-element="MantineOverlay" data-sentry-source-file="index.tsx" />
            <>
                {children}
            </>
        </Box>;
};
export default LoadingOverlay;