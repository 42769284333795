// NotFoundImage.tsx
import React, { JSX } from 'react';
import { Container, Title, Text, Button, SimpleGrid } from '@mantine/core';
import image from './image.svg';
import { Image } from '@mantine/core';
import classes from './NotFoundComponent.module.css';
interface NotFoundImageProps {
  goToText?: string;
  goToLink?: string;
}
export const NotFoundComponent = ({
  goToText = "Get back to home page",
  goToLink = "/"
}: NotFoundImageProps): JSX.Element => {
  return <Container className={classes.root} data-sentry-element="Container" data-sentry-component="NotFoundComponent" data-sentry-source-file="index.tsx">
      <SimpleGrid spacing={{
      base: 40,
      sm: 80
    }} cols={{
      base: 1,
      sm: 2
    }} data-sentry-element="SimpleGrid" data-sentry-source-file="index.tsx">
        <Image src={image.src} className={classes.mobileImage} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        <div>
          <Title className={classes.title} data-sentry-element="Title" data-sentry-source-file="index.tsx">Something is not right...</Title>
          <Text color="dimmed" size="lg" data-sentry-element="Text" data-sentry-source-file="index.tsx">
            Page you are trying to open does not exist. You may have mistyped the address, or the
            page has been moved to another URL. If you think this is an error contact support.
          </Text>
          <Button variant="outline" size="md" mt="xl" className={classes.control} component="a" href={goToLink} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            {goToText}
          </Button>
        </div>
        <Image src={image.src} className={classes.desktopImage} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
      </SimpleGrid>
    </Container>;
};