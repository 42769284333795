export const SIGNUP_MUTATION = () => `
  mutation SignUp(
    $name: String!,
    $email: String!,
    $password: String!,
    $creator: Boolean = false,
    $otp: String!
  ) {
    createUser(name: $name, email: $email, password: $password, creator: $creator, otp: $otp) {
      name
      email
    }
  }
`;

export const LOGIN_MUTATION = () => `
  mutation Login(
    $email: String!,
    $password: String!
  ) {
    login(email: $email, password: $password) {
      message
    }
  }
`;

export const LOGOUT_MUTATION = () => `
  mutation Logout {
    logout {
      message
    }
  }
`;

export const GOOGLE_LOGIN_MUTATION = `
  mutation Login($code: String!) {
    googleLogin(code: $code) {
      whatsnxt_blog_access_token
      user {
        id
        name
        email
      }
    }
  }
`;

export const GET_USER_QUERY = () => `
  query GetUserProfile {
    getUserProfile {
      id
      type
      name
      email
    }
  }
`;

export const OTP_MUTATION = () => `
  mutation otp(
    $name: String!,
    $email: String!,
  ) {
    sendOtp(name: $name, email: $email) {
      email
    }
  }
`;
