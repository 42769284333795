"use client";

// Mark this component as client-side
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
interface MobileDetectorProps {
  children: (isMobile: boolean) => React.ReactNode;
}
export const MobileDetector: React.FC<MobileDetectorProps> = ({
  children
}) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  return <>{children(isMobile)}</>;
};