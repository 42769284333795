'use client';

import React from 'react';
import { Box } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { NavbarDesktop } from './Desktop';
import { NavbarMobile } from './Mobile';
import useAuth from '../../hooks/Authentication/useAuth';
type LinkType = {
  url: string;
  title: string;
  linkType: string;
};
interface IMenuLink {
  title: string;
  url: string;
  icon?: any;
  linkType: string;
}
type headerProps = {
  authenticatedMenuLinks: LinkType[];
  copyRight: string;
  links: IMenuLink[];
};
const Navbar = ({
  links,
  authenticatedMenuLinks
}: headerProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const {
    user
  } = useAuth();
  const [drawerOpened, {
    toggle: toggleDrawer,
    close: closeDrawer
  }] = useDisclosure(false);
  return <Box pb={isMobile ? 0 : 40} data-sentry-element="Box" data-sentry-component="Navbar" data-sentry-source-file="Index.tsx">
      <NavbarDesktop initialUser={user} navLinks={links} authenticatedMenuLinks={authenticatedMenuLinks} drawerOpened={drawerOpened} toggleDrawer={toggleDrawer} data-sentry-element="NavbarDesktop" data-sentry-source-file="Index.tsx" />
      <NavbarMobile user={user} navLinks={links} authenticatedMenuLinks={authenticatedMenuLinks} drawerOpened={drawerOpened} closeDrawer={closeDrawer} data-sentry-element="NavbarMobile" data-sentry-source-file="Index.tsx" />
    </Box>;
};
export default Navbar;