import Link from 'next/link';
import Image from 'next/image';
import { Anchor, Divider, LoadingOverlay } from '@mantine/core';
import { Card, Text, Badge, Group } from '@mantine/core';
import { useState } from 'react';
function TutorialCard({
  tutorial
}: any) {
  const [loading, setLoading] = useState(false);
  const handleNavigation = () => {
    setLoading(true);
  };
  return <>
      {/* Overlay Loader */}
      {loading && <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{
      radius: "sm",
      blur: 2
    }} />}

      {tutorial.listed && <Anchor component={Link} href={`/content/${tutorial.slug}`}>
          <Card padding="xs" radius="md" withBorder onClick={handleNavigation}>
            <Card.Section>
              <Image src={tutorial?.imageUrl} alt={tutorial.title} width={300} height={200} />
            </Card.Section>
            <Divider />
            <Group justify="space-between" mt="md" mb="sm" pl="xs">
              <Badge color="pink">{tutorial.categoryName}</Badge>
            </Group>
            <Text size="md" mb="sm" pl="xs" lineClamp={2}>
              {tutorial.title}
            </Text>
          </Card>
        </Anchor>}
    </>;
}
export default TutorialCard;