'use client';

import { Card, Image, Text, Skeleton } from '@mantine/core';
import classes from './index.module.css';
import { Group } from '@mantine/core';
import { useRouter } from 'next/navigation';
import React from 'react';
interface Props {
  imageUrl?: string;
  title?: string;
  updatedAt?: string;
  categoryName?: string;
  author?: string;
  slug?: string;
}
export default function PopularPost(props: Props) {
  const {
    imageUrl,
    title,
    updatedAt,
    author,
    categoryName,
    slug
  } = props;
  const router = useRouter();
  return <Card withBorder my={'md'} radius="md" p={0} className={classes.card} onClick={() => {
    router.push(`/content/${slug}`);
  }} data-sentry-element="Card" data-sentry-component="PopularPost" data-sentry-source-file="index.tsx">
            <Group wrap="nowrap" gap={5} data-sentry-element="Group" data-sentry-source-file="index.tsx">
                {title ? <Image alt={title} radius={'sm'} src={imageUrl} style={{
        width: '40%'
      }} /> : <Skeleton style={{
        width: '40%',
        height: 100
      }} />}
                {title ? <div className={classes.body}>
                    <Text tt="uppercase" c="dimmed" fw={600} size="xs">
                        {categoryName}
                    </Text>
                    <Text className={classes.title} size='sm' mb="xxs" lineClamp={1}>
                        {title}
                    </Text>
                    <Group gap="xs" className={classes.author}>
                        <Text size="xs" c="dimmed">
                            {updatedAt}
                        </Text>
                    </Group>
                </div> : <div style={{
        width: '55%'
      }}>
                        <Skeleton height={8} radius="xl" />
                        <Skeleton height={8} mt={6} radius="xl" />
                        <Skeleton height={8} mt={6} width="70%" radius="xl" />
                    </div>}
            </Group>
        </Card>;
}