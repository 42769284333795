import api from '@whatsnxt/core-util/src/ApiClient/xiorInstance';
import {
  EDIT_BLOG_MUTATION,
  EDIT_TUTORIAL_MUTATION,
  POST_BLOG_MUTATION,
  POST_TUTORIAL_MUTATION,
} from './gqlQueries/formQuery';

export const FormAPI = {
  createBlog: async function (payload: any) {
    const { data } = await api.request({
      data: {
        query: POST_BLOG_MUTATION,
        variables: {
          title: payload.title,
          description: payload.description,
          categoryName: payload.categoryName,
          categoryId: payload.categoryId || null,
          subCategory: payload.subCategory || null,
          nestedSubCategory: payload.nestedSubCategory || null,
          cloudinaryAssets: payload.cloudinaryAssets || null,
          imageUrl: payload.imageUrl || null,
          wordCount: payload.wordCount
        },
      },
    });
    return data.data ? data.data.addPost : data.errors;
  },
  updateBlog: async function (id: string, payload: any) {
    const { data } = await api.request({
      data: {
        query: EDIT_BLOG_MUTATION,
        variables: {
          id,
          title: payload.title,
          description: payload.description,
          categoryName: payload.categoryName,
          categoryId: payload.categoryId,
          subCategory: payload.subCategory || null,
          imageUrl: payload.imageUrl || null,
          nestedSubCategory: payload.nestedSubCategory || null,
          cloudinaryAssets: payload.cloudinaryAssets || null,
          wordCount: payload.wordCount
        },
      },
    });
    return data.data ? data.data.editPost : data.errors;
  },
  createTutorial: async function (payload: any) {
    const { data } = await api.request({
      data: {
        query: POST_TUTORIAL_MUTATION,
        variables: {
          title: payload.title,
          description: payload.description || '',
          categoryName: payload.categoryName,
          subCategory: payload.subCategory || null,
          imageUrl: payload.imageUrl || null,
          nestedSubCategory: payload.nestedSubCategory || null,
          categoryId: payload.categoryId,
          tutorials: payload.tutorials,
          published: false,
          cloudinaryAssets: payload.cloudinaryAssets || null
        },
      },
    });
    return data.data ? data.data.addTutorial : {};
  },
  updateTutorial: async function (id: string, payload: any) {
    const { data } = await api.request({
      data: {
        query: EDIT_TUTORIAL_MUTATION,
        variables: {
          id,
          title: payload.title,
          description: payload.description || '',
          categoryName: payload.categoryName,
          subCategory: payload.subCategory || null,
          imageUrl: payload.imageUrl || null,
          nestedSubCategory: payload.nestedSubCategory || null,
          categoryId: payload.categoryId,
          tutorials: payload.tutorials,
          published: false,
          cloudinaryAssets: payload.cloudinaryAssets || null
        },
      },
    });
    return data.data ? data.data.editTutorial : {};
  },
};
